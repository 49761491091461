.impressum-container {
  margin-top: 80px !important;
  margin-bottom: 100px !important;
}

.impressum-container h2 {
  color: #4cbfa4;
}

.impressum-container h2 {
  color: ccc;
}