.measurability-container {
    padding-top: 80px;
    padding-bottom: 200px;
}

.dashboard-item {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
}

.measurability-container table {
    width: 100%;
    border-collapse: collapse;
    font-size: 18px;
}

.measurability-container th, .measurability-container td {
    text-align: left;
    padding: 8px;
    width: 33.33%;
}

.measurability-container th {
    font-weight: bold;
    border-bottom: 2px solid #ccc;
}


.measurability-container table {
    border: none; /* No outline */
}