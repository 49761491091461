/* General styles for the login container */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

h2 {
    margin-bottom: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
}

.input-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.input-section label {
    margin-bottom: 5px;
    font-weight: bold;
}

.input-section input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-button {
    padding: 10px;
    font-size: 16px;
    background-color: #4cbfa4;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.login-button:hover {
    background-color: #2d7363;
}

.error-message {
    color: red;
    margin-top: 10px;
}

/* Specific styles to vertically position username at the top and password at the bottom */
.login-form {
    justify-content: space-between; /* Vertically distribute form elements */
}

.input-section:first-of-type {
    margin-bottom: auto; /* Pushes username input to the top */
}

.input-section:last-of-type {
    margin-top: auto; /* Pushes password input to the bottom */
}
