/* Overlay for the popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999; /* We are using z-index levels by appending 9s (9, 99, 999) */
    overflow-y: auto;
  }
  
  /* Popup box */
  .popup-box {
    background-color: #313131f6;
    padding: 2%; /* Padding adjusted for responsiveness */
    border-radius: 12px;
    width: 95%; /* Default width is 80% of the screen width */
    max-width: 800px; /* Maximum width */
    max-height: 90%; /* Limit height to avoid overflow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    display: flex;
    flex-direction: column; /* Ensure content is stacked vertically */
    justify-content: flex-start; /* Align content at the top */
    align-items: center; /* Horizontally center content */
    overflow-y: auto;
  }
  
  /* Video styling - fixed height to ensure it's above the text */
  .popup-video {
    width: 95%; /* Full width of the popup */
    max-width: 95%; /* Prevent video from exceeding popup width */
    height: auto;
    max-height: 50vh; /* Scale video height for smaller screens */
    margin-top: 25px; 
    margin-bottom: 20px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Light shadow around the video */
    object-fit: cover; /* Ensure video fits without distortion */
    flex-shrink: 0; /* Prevent video from shrinking */
  }
  
  /* Styling the unordered list inside the popup */
  .popup-box ul {
    list-style-type: none;
    color: #ccc;
    padding: 0;
    margin: 0;
    margin-top: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align the list items at the top */
    align-items: center; /* Center the list items horizontally */
    overflow-y: auto; /* Allow scrolling */
    flex-grow: 1; /* Allow the text to grow and take available space */
  }
  
  /* Styling for each list item */
  .popup-box ul li {
    margin-bottom: 3%;
    font-size: 20px; 
    max-width: 90%; /* Max width for list items to avoid stretching */
    text-align: center;
  }

  strong {
    color: #4cbfa4;
  }
  
  /* Styling the close button */
  .popup-close-button {
    background-color: #4cbfa4;
    color: white;
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s ease;
    padding: 10px;
  }

  
  .popup-close-button:hover {
    background-color: #333; /* Darker mint when hovered */
    color: #4cbfa4;
  }
  
  /* Media queries for mobile */
  @media (max-width: 768px) {
    .popup-box {
      width: 95%; /* Use almost full width for smaller screens */
      max-height: 80%; /* Reduce height slightly on smaller screens */
      overflow-y: auto; /* Allow scrolling */
    }
  
    .popup-video {
      max-height: 50vh; /* Scale video height for smaller screens */
      object-fit: cover; /* Ensure video fits without distortion */
    }
  }
  
  @media (max-width: 480px) {
    .popup-box {
      padding: 15px; /* Reduce padding for very small screens */
      border-radius: 5px;
      max-height: 90%; /* Adjust max height for very small screens */
      overflow-y: auto; /* Allow scrolling */
    }
  
    .popup-close-button {
      padding: 10px 15px; /* Smaller close button */
      font-size: 4vw; /* Make font size responsive based on viewport width */
    }
  }
  