@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&family=Londrina+Shadow&display=swap');


body {
  margin: 0;
  color: #CCC;
  background-color: #222422 !important;
  font-family: "Afacad Flux", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  font-family: "Afacad Flux", sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #00000000;
  padding: 20px;
}

.footer {
font-family: "Afacad Flux", sans-serif;
color: #4cbfa475;
position: fixed;
bottom: 0;
width: 100%;
background-color: #222422;
}

.logo {
  color: #4cbfa4;
  text-decoration: none;
  font-size: 40px;
  font-weight: 700;
}

.custom-link:hover,
.custom-link:focus,
.custom-link:active,
.custom-link:visited {
  color: #4cbfa4;
  text-decoration: none;
}
