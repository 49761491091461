.App {
  background-color: #222422;
  color: #CCC;
}

.main-container {
  height: 100dvh;
  margin: 0 10% 0 10%;
  padding: 0;
  pointer-events: all;
}

.input-container {
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.url-input {
  margin-top: 10px;
} 

.column-input-container {
    justify-content: center;
}

.button-icon {
    margin: 0 1.5%; /* Adjust spacing between icons */
    text-decoration: none; /* Remove underline */
    color: #4cbfa4; /* Set default color for icon */
}

.button-icon:hover {
    color: #0ea17f; /* Change color on hover */
}

.response {
  font-size: 20px;
}

.exists-container a {
  color: #4cbfa4;
}

.url-input {
  width: 100%;
  color: #ccc;
  border: none;
  border-radius: 0;
  background-color: #0000;
  border-bottom: 2px solid;
}
.url-input:hover {
  border-bottom: 2px solid #4cbfa4;
}

.url-input:focus {
  border: none;
  outline: none;
  box-shadow: none; 
  border-color: inherit;
  border-bottom: 2px solid;
}

.border-white {  
  border-bottom-color: #ced4da;
}

.border-red {
  border-bottom-color: #bf4c4c;
}

.border-green {
  border-bottom-color: #4cbfa4;
}

.submit-button {
  margin-top: 20px;
  border-radius: 50px;
  padding: 10px;
  color: white;
  background-color: #4cbfa4;
  border: none;
  font-size: 20px;
}

.submit-button:hover {
  background-color: #333; /* Darker mint when hovered */
  color: #4cbfa4;
}

@media (max-width: 767px) {
  .main-container {
    margin: 0 10px 0 10px; /* mobile padding */
  }
  .www-input-group {
    padding-bottom: 20px;
  }
}

.error-text {
  color: red;
}