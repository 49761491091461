/* Styling for the toast container */
.toast-container {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999; /* We are using z-index levels by appending 9s (9, 99, 999) */
  width: auto;
  padding: 10px;
  cursor: pointer; 
  opacity: 1; /* Default opacity */
  transition: opacity 1s ease-in-out; /* Smooth transition */
}

/* Apply faded effect with reduced opacity */
.toast-container.faded {
  opacity: 0.2; 
  transition: opacity 1s ease-in-out; /* Smooth transition */
}

/* Styling for the toast box */
.toast-box {
  background-color: #333;
  color: ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 16px;
  max-width: 500px;
  width: 100%;
  pointer-events: all;
}

/* Styling for toast text */
.toast-text {
  font-size: 20px;
  color: #4cbfa4;  
  font-weight: 500;
}

/* Additional font styling */
.font-medium {
  font-weight: 500;
}
